import {
  EventEmitter,
  Inject,
  Injectable,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { ApiService } from '../../../ngxSpring/api.service';
import {
  CaseCount,
  Certificate,
  IamportPayment,
  LawSchoolAuthorization,
  LboxPermission,
  LboxUser,
  LboxUserData,
  NonProfitOrganizationInfo,
  Page,
  PricePlanPayment,
  SmsVerify,
  User,
  UserHasPlan,
  UserProperties,
  UserRole,
} from '../../../ngxSpring/api.model';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';
import { firstValueFrom, forkJoin, NEVER, of } from 'rxjs';
import { ScriptService } from 'ngx-script-loader';
import {
  day,
  deleteAllCookies,
  isChrome,
  isEdge,
  isProduction,
  isSafari,
  later,
  loginUrl,
} from '../core/util';
import { RefundDataComponent } from './dialogs/refund-data/refund-data.component';
import { PaymentComponent } from './dialogs/payment/payment.component';
import { ChangeTypeComponent } from './dialogs/change-type/change-type.component';
import { ChangePaymentComponent } from './dialogs/change-payment/change-payment.component';
import { DatePipe, DecimalPipe, isPlatformServer } from '@angular/common';
import { CopyService } from '../core/copy.service';
import { SelectCasesComponent } from './dialogs/select-cases/select-cases.component';
import { TrackService } from '../track/track.service';
import { LocalStorageService } from '../core/local-storage.service';
import jwtDecode from 'jwt-decode';
import { AlarmService } from '../core/alarm.service';
import { CongratsComponent } from './dialogs/congrats/congrats.component';
import * as moment from 'moment';
import {
  lqaDistricts,
  LqaDistrictSettingComponent,
} from '../lqa/lqa-district-setting/lqa-district-setting.component';
import { LqaProfileUpdateComponent } from '../shared/lqa-profile-update/lqa-profile-update.component';
import { LawSchoolAuthorizeComponent } from './dialogs/law-school-authorize/law-school-authorize.component';
import { identify, Identify } from '@amplitude/analytics-browser';
import { log } from '../../log';
import { DeviceService } from '../core/device.service';
import { SmsVerifyComponent } from '../core/sms-verify/sms-verify.component';
import { LawyerVerifyByUploadComponent } from '../core/lawyer-verify-by-upload/lawyer-verify-by-upload.component';
import { UserpilotService } from '../shared/userpilot.service';
import { localStorageKeys, sessionStorageKeys } from './constants';
import { LawyerVerifyDialogV2Component } from '../core/lawyer-verify-dialog-v2/lawyer-verify-dialog-v2.component';
import { NEW_LBOX_PREFIX, V2_PAGE_PATH } from 'src/consts';
import { CUSTOM_EVENT_NAME } from '../constants';
import { convertJobDetailsToLogProperty } from './utils';

interface JWTDecodedData {
  id: number;
  name: string;
  email: string;
  lboxUserData: boolean;
  role: UserRole;
}

export function userFromToken(token) {
  if (!token) return;

  try {
    const decoded = jwtDecode<JWTDecodedData>(token);
    const user: User = {} as any;
    user.id = decoded.id;
    user.name = decoded.name;
    user.email = decoded.email;
    user.lboxUserData = decoded.lboxUserData;
    user.role = decoded.role;
    return user;
  } catch (e) {
    log(e);
  }
}

@Injectable({ providedIn: 'root' })
export class UserService {
  menuOpen = new EventEmitter();
  lqaDistrictsValues;

  get lqaData() {
    return this.lboxUser?.lboxUserData?.lqaData;
  }

  async updateLqaProfile(part?) {
    if (!this.authorized) {
      document
        .querySelector('.loading-bar.loading-bar--active')
        ?.classList?.remove('loading');
      if (!(await this.authorizeLawyer({}))) {
        this.alert.pop('변호사 인증을 취소했습니다.');
        return;
      }
    }
    await this.ensureUserLoaded();
    return this.alert.open(LqaProfileUpdateComponent, part, {
      width: '596px',
      disableClose: true,
    });
  }

  lqaCount;
  _menuList: ({ name; logType?; path; sub?; subAction? } | string)[];

  userUpdated = new EventEmitter();
  caseCountsUpdated = new EventEmitter();
  lboxUser: LboxUser;
  plan: UserHasPlan;
  payment: IamportPayment;
  lastPlan: UserHasPlan = {} as UserHasPlan;
  candidateCases: any[];
  counts: number;
  caseCountListData?: Page<CaseCount>;
  canUseLegalAnalytics = false;

  get point() {
    if (!this.lboxUser) return 0;
    return this.lboxUser.point;
  }

  get isTypeLawSchoolStudent() {
    return this.lboxUser?.job?.[0] == '법학전문대학원생';
  }

  get hasMembershipChance() {
    return (!this.lastPlan || !this.user) && !this.plan;
  }

  get email() {
    if (!this.user) return;
    return this.user.email || this.user.snsEmail;
  }

  get isAdmin() {
    return this.hasPermission('Admin');
  }

  hasPermission(permission: LboxPermission) {
    if (!this.user) {
      return;
    }
    return this.user.permissions.includes(permission);
  }

  get logged() {
    return !!this.user;
  }

  user: User;
  userLoaded;

  constructor(
    private api: ApiService,
    private alert: AlertService,
    private router: Router,
    private script: ScriptService,
    private clipboard: CopyService,
    private decimal: DecimalPipe,
    private zone: NgZone,
    private ga: TrackService,
    private localStorage: LocalStorageService,
    private alarm: AlarmService,
    public deviceService: DeviceService,
    private datePipe: DatePipe,
    private userpilotService: UserpilotService,
    @Inject(PLATFORM_ID) private platform,
    @Optional() @Inject('lboxToken') public token: any,
  ) {
    if (isPlatformServer(platform)) {
      return;
    }
    if (token) {
      this.user = userFromToken(token);
    } else if (!this.user && !isPlatformServer(this.platform)) {
      this.user = userFromToken(getCookie('lboxToken'));

      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
    }

    this.lqaCountUpdate();
    this.checkCookie();
    void this.updateFromServer();
    void firstValueFrom(this.userUpdated).then(async () => {
      if (
        !location?.href?.endsWith('#check') &&
        !location?.href?.includes('login') &&
        !location?.href?.includes('register')
      ) {
        if (this.isLawyer) {
          if (await this.checkCandidate()) return;
        }
        if (this.checkLqaClientTarget()) return;
      }
    });
    this.api.caseCount.getCaseCountList(0, 1).subscribe((list) => {
      this.caseCountListData = list;
      this.counts = Math.round(list.content?.[0]?.realCount / 10000);
      this.caseCountsUpdated.emit();
    });

    this.setNonProfitOrganizationInfo();
  }

  updateFromServer(redirect = true) {
    return new Promise<User>((r) => {
      this.api.user.getCurrentUser().subscribe((user) => {
        this.openPopup(user);
        r(this.updateUser(user, redirect));
      });
    });
  }

  lqaCountUpdate() {
    this.api.matchedLawyer.newCount().subscribe((c) => {
      this.lqaCount = c;
    });
  }

  /**
   * 비영리단체 정보 세팅
   */
  setNonProfitOrganizationInfo() {
    if (!this.isPublicDomain) {
      window.sessionStorage.removeItem(sessionStorageKeys.NON_PROFIT_ORG_INFO);
      return;
    }

    if (
      !window.sessionStorage.getItem(sessionStorageKeys.NON_PROFIT_ORG_INFO)
    ) {
      this.api.userHasPlan
        .getNonProfitOrganizationInfo()
        .pipe(
          retry(3),
          catchError(() => of(null)),
        )
        .subscribe({
          next: (value) => {
            window.sessionStorage.setItem(
              sessionStorageKeys.NON_PROFIT_ORG_INFO,
              JSON.stringify(value),
            );
          },
        });
    }
  }

  /** 비영리단체 세션 여부 */
  hasNonProfitOrganizationInfo(): boolean {
    return !!window.sessionStorage.getItem(
      sessionStorageKeys.NON_PROFIT_ORG_INFO,
    );
  }

  /** 접속한 비영리단체 정보 */
  getNonProfitOrganizationInfoFromSession(): NonProfitOrganizationInfo | null {
    const nonProfitOrganizationInfoSerialized = window.sessionStorage.getItem(
      sessionStorageKeys.NON_PROFIT_ORG_INFO,
    );
    try {
      return JSON.parse(nonProfitOrganizationInfoSerialized);
    } catch {
      return null;
    }
  }

  /**
   * 기능 제한(파일 업로드, 텍스트 입력 등)이 걸린 비영리단체 유저 판단 로직
   */
  get isRestrictedUser() {
    const nonProfitOrganizationInfo =
      this.getNonProfitOrganizationInfoFromSession();
    return (
      this.isPublicDomain &&
      nonProfitOrganizationInfo?.securityLevel === 'UPLOAD_AND_INPUT_DISABLE'
    );
  }

  private openPopup(user: User) {
    if (user?.data?.membership) {
      void this.alert
        .confirm(
          '내 판례등록 포인트 일괄 적립',
          '스탠다드 플랜 혜택 보기',
          `${user.name} 변호사님께서 등록해주신 판례에 대한 포인트가 일괄 지급되었습니다. 포인트를 사용하여 스탠다드 플랜에 가입해보세요. <br>\n` +
            '\n <br>' +
            `등록 판례수: ${
              user.data.membership
            }건<br>지급 포인트: ${this.decimal.transform(
              user.data.membership * 5000,
            )} 포인트\n <br>` +
            '\n <br>' +
            '엘박스 커뮤니티에 기여해주셔서 감사합니다.',
          '확인',
        )
        .then((v) => {
          if (v) {
            void this.router.navigateByUrl('/user/pricing');
            this.api.user.removeData('membership').subscribe();
            return;
          }
          if (v === null) {
            this.api.user.removeData('membership').subscribe();
            return;
          }
        });
    }
    if (user?.data?.email) {
      void this.alert
        .confirm(
          '이메일 주소 확인',
          '이메일 주소 변경하기',
          `※ 본 메시지는 이메일 주소 오류 등의 이유로 11월 22일 발송된 "엘박스 멤버십 출시 안내 메일"이 반송된 분들에 한해 보여집니다.<br><br>
${user.name}님 엘박스를 이용해주셔서 감사합니다.<br>
엘박스에서 보내드리는 다양한 소식(예: 신규서비스 출시, 프로모션 이벤트 등)이 보다 원활히 전달될 수 있도록 엘박스에 등록된 이메일 주소가 정확한지 확인 부탁드립니다. 현재 이용 중인 이메일 주소가 맞다면 스팸 설정을 확인해주세요.<br><br>
${user.name}님의 현재 등록된 이메일 주소 : ${user.email}`,
          '닫기',
        )
        .then((v) => {
          if (v) {
            this.changeEmail();
            this.api.user.removeData('email').subscribe();
            return;
          }
          if (v === null) {
            this.api.user.removeData('email').subscribe();
            return;
          }
        });
    }
  }

  get isLawyer() {
    if (!this.user) return false;
    return this.lboxUser?.job?.[0] === '변호사';
  }

  get authorized() {
    if (!this.isLawyer) return false;
    return this.hasAuthorizationInfo;
  }

  get unauthorized() {
    if (!this.isLawyer) return false;
    return !this.hasAuthorizationInfo;
  }

  get hasAuthorizationInfo() {
    return this.lboxUser.lawyerVerified;
  }

  async updateLboxUser() {
    const properties = isProduction()
      ? await firstValueFrom(this.api.user.getCurrentUserProperties())
      : null;

    this.api.lboxUser.getLboxUser().subscribe((lboxUser) => {
      this.lboxUser = lboxUser;

      const userInfo = this.getUserInfo(properties, this.user);

      const userProperties = new Identify();

      Object.keys(userInfo).forEach((it) => {
        if (!userInfo[it]) return;
        userProperties.set(it, userInfo[it]);
      });

      identify(userProperties, { user_id: String(this.lboxUser.userId) });
    });
  }

  public lawSchoolAuthorization: LawSchoolAuthorization;

  /**
   * 로스쿨 '인증중' 여부 판단
   */
  get authorizingLawSchool() {
    return (
      this.lawSchoolAuthorization &&
      !this.lawSchoolAuthorization.rejectReason &&
      !this.lawSchoolAuthorization.authorized
    );
  }

  /**
   * 로스쿨 '인증' 상태 판단
   */
  get isAuthorizedLawSchoolStudent() {
    return (
      (this.isTypeLawSchoolStudent &&
        this.lawSchoolAuthorization?.authorized) ??
      false
    );
  }

  /**
   * 로스쿨 '마인증' 상태 판단
   */
  get isUnauthorizedLawSchoolStudent() {
    return (
      (this.isTypeLawSchoolStudent &&
        !this.lawSchoolAuthorization?.authorized) ??
      false
    );
  }

  updateUser(u: User, redirect = true): User {
    this.user = u;
    if (this.logged) {
      void this.deviceService.verify();
    }

    if (u && !u.lboxUserData) {
      this.userLoaded = true;
      return u;
    }

    if (u) {
      forkJoin([
        this.api.lboxUser.getLboxUser(),
        this.api.userHasPlan.getPlanType(),
        this.api.iamportPayment.registeredPayment(),
        this.api.userHasPlan.lastInfo(),
        this.api.lboxUser.getLegalAnalyticsCanUse(),
      ]).subscribe((v) => {
        this.userLoaded = true;
        const lboxUser = v[0];
        this.plan = v[1];
        this.payment = v[2];
        this.setLboxUser(lboxUser);
        this.lastPlan = v[3];
        this.canUseLegalAnalytics = v[4].canUse;
        this.userUpdated.emit(u);
        this.setDistrictViewValues();

        if (this.isTypeLawSchoolStudent) {
          this.api.lawSchoolAuthorization
            .getMyAuthorization()
            .subscribe((v) => {
              this.lawSchoolAuthorization = v;
            });
        }

        void this.sendUserData(u);
        this.lboxUser.user = u;
      });
    } else {
      this.userUpdated.emit(u);
    }
    if (redirect && u && !u.role && !u.lboxUserData) {
      void this.router.navigateByUrl('/register/transfer');
      return u;
    }
    return u;
  }

  /** 사용중인 플랜명 */
  getPlanName(userHasPlan?: Partial<UserHasPlan>) {
    if (this.hasNonProfitOrganizationInfo()) {
      return '비영리단체 플랜';
    }
    switch (userHasPlan?.plan) {
      case 'LAW_SCHOOL':
        return '로스쿨 플랜';
      case 'MEMBERSHIP':
        return userHasPlan?.companyPlan ? '비즈니스 플랜' : '스탠다드 플랜';
      default:
        return '플랜 정보 없음';
    }
  }

  async logout() {
    this.clearSessionStorage();

    await this.logoutOnly();

    void this.router.navigateByUrl('/').then(() => {
      window?.location.reload();
    });
  }

  clearSessionStorage() {
    window?.sessionStorage.removeItem(sessionStorageKeys.NON_PROFIT_ORG_INFO);
  }

  async logoutOnly() {
    try {
      await firstValueFrom(
        this.api.user.logout(await this.deviceService.deviceId()),
      );
    } catch (e) {}
    deleteAllCookies();
    this.localStorage.clearExcept([
      localStorageKeys.TOP_BANNER_NOT_SHOWN_UNTIL,
    ]);
    this.user = null;
    this.lboxUser = null;
  }

  async loginCheck() {
    if (!this.logged) {
      await this.router.navigateByUrl(loginUrl());
      this.alert.pop('로그인이 필요한 서비스입니다.');
      throw new Error('로그인이 필요한 서비스입니다.');
    }
  }

  async getCertificate(): Promise<Certificate> {
    /** 포트원 third party script 로드 */
    await firstValueFrom(this.script.loadScript('/assets/js/jquery.min.js'));
    await firstValueFrom(this.script.loadScript('/assets/js/iamport.min.js'));
    /** 포트원 인증 로직 실행 */
    return await this.impPromise();
  }

  async smsCertPromise(userId: number, createdAt: number): Promise<SmsVerify> {
    const phone = await firstValueFrom(
      this.api.smsVerify.requestSms(userId, createdAt),
    );
    return this.alert.open(SmsVerifyComponent, phone, { width: '456px' });
  }

  /**
   * 포트원 인증 로직
   */
  private impPromise(): Promise<Certificate> {
    return new Promise((r, e) => {
      /** 전역에 설정된 포트원 객체 할당 */
      const IMP = (window as any).IMP;
      /** 포트원 init */
      IMP.init('imp21019456');
      /** 포트원을 통해 휴대전화 본인인증 진행 후 callback 함수 실행 */
      IMP.certification({}, (rsp) => {
        // callback
        if (rsp.success) {
          this.api.iamportCertificate
            .getLboxIamportCertificate(rsp.imp_uid)
            .pipe(
              catchError((er) => {
                e(er);
                return NEVER;
              }),
            )
            .subscribe((c) => {
              r(c);
            });
          return;
        }
      });
    });
  }

  changeEmail() {
    void this.alert
      .prompt(
        [
          {
            placeholder: '새 이메일주소',
            validator: (v) => {
              return /[^@]+@[^\\.]+\..+/.exec(v);
            },
            action: {
              name: '인증번호 전송',
              action: (v) => {
                if (!/[^@]+@[^\\.]+\..+/.exec(v)) {
                  this.alert.pop('유효하지 않은 이메일입니다.');
                  return;
                }
                this.api.user.existCheck(v).subscribe((r) => {
                  if (r) {
                    this.alert.pop('이미 사용중인 이메일입니다.');
                    return;
                  }
                  this.api.emailVerify
                    .requestCodeEmailLbox(v)
                    .subscribe((v) => {
                      this.alert.pop('인증 이메일이 전송되었습니다.');
                    });
                });
              },
            },
          },
          {
            placeholder: '인증코드',
            validator: (v) => {
              return /^\d{6}$/.exec(v);
            },
          },
        ],
        '이메일 주소 변경',
        '변경하기',
        null,
        '<div class="mt-16">' +
          '로그인에 사용할 이메일 주소가 변경됩니다.<br>' +
          '각종 서비스 알림 및 정보도 변경된 주소로 발송됩니다.</div>',
        null,
        null,
        true,
      )
      .then((v) => {
        if (!v || !v[0] || !v[1]) return;
        this.api.user.updateEmail(v[0], v[1]).subscribe(() => {
          this.user.email = v[0];
          this.alert.pop('이메일이 변경 되었습니다.');
        });
      });
  }

  cancelMembership(data: PricePlanPayment, ref?) {
    void this.alert
      .deleteConfirm(
        '결제 취소',
        '결제 취소하기',
        '결제를 취소할 경우, 스탠다드 플랜은 자동으로 해지되며 더 이상 <br />' +
          '엘박스의 스탠다드 플랜 기능을 이용할 수 없습니다.<br />' +
          '정말로 취소하시겠습니까?',
        '닫기',
      )
      .then((v) => {
        if (!v) return;
        this.api.pricePlanPayment.cancelPayment(data.id).subscribe((v) => {
          void this.updateFromServer();

          data.cancelled = true;

          if (ref) {
            ref.close(true);
          } else {
            void this.alert.close();
          }

          void this.alert.open(RefundDataComponent, v);
        });
      });
  }

  checkFailed() {
    this.api.userHasPlan.lastFailedChecked().subscribe(() => {
      this.lastPlan.failedChecked = true;
    });
  }

  membershipStart(referrerCode = null) {
    if (!this.logged) {
      this.openMembershipNudgingModal();
      return;
    }

    this.api.userHasPlan.getMembershipInfo(referrerCode).subscribe((v) => {
      void this.alert
        .open(
          PaymentComponent,
          { payInfo: v, code: referrerCode },
          { id: 'membership_register' },
        )
        .then(() => {
          void this.updateFromServer();
        });
    });
  }

  async changeType(yearOnly = false) {
    const v = await this.alert.open(
      ChangeTypeComponent,
      {
        job: this.lboxUser?.job ? this.lboxUser.job : [],
        yearOnly: yearOnly,
      },
      {},
      {
        minWidth: '500px',
        maxWidth: '500px',
      },
    );
    if (v) {
      this.setLboxUser(v);
      this.userUpdated.emit();
    }
  }

  changePayment() {
    void this.alert.open(ChangePaymentComponent, null, null, {
      width: '420px',
    });
  }

  private setLboxUser(lboxUser: LboxUser) {
    this.lboxUser = lboxUser;
    if (this.isLawyer) {
      this.lboxUser.job[5] = lboxUser.association;
      if (!lboxUser.lboxUserData) lboxUser.lboxUserData = {} as LboxUserData;
      lboxUser.lboxUserData.association = lboxUser.association;
    }
    this.updateMenuList();
  }

  private updateMenuList() {
    this._menuList = [
      '계정',
      { name: '계정/요금제', path: '/user/myPage' },
      { name: '알림 설정', path: '/user/alarm' },
      { name: '결제 내역', path: '/user/logs/payment' },
      {
        name: '포인트 내역',
        path: '/user/logs/point',
        sub: this.decimal.transform(this.lboxUser.point) + 'p',
      },
      {
        name: '친구 초대',
        path: '/user/invite',
        sub: '<span class="color-primary">30,000p 받기</span>',
      },
      '서비스 이용 내역',
      { name: '등록요청한 판례', path: '/user/requestCases' },
      { name: '검색조건 알림', path: '/user/subscribe' },
    ];

    if (this.isLawyer) {
      this._menuList.splice(6, 0, {
        name: '내 수행사건 등록',
        logType: '내 판례등록',
        path: '/user/myCase',
      });
    }

    if (this.isRestrictedUser) {
      this._menuList = this._menuList.filter((menuItem) => {
        if (typeof menuItem === 'object') {
          return (
            menuItem.name !== '법률 Q&A 설정' &&
            menuItem.name !== '내 판결문 등록' &&
            menuItem.name !== '검색조건 알림' &&
            menuItem.name !== '친구 초대'
          );
        } else {
          return true;
        }
      });
    }
  }

  authorizeLawyer({ channel }: { channel?: string }) {
    return new Promise((r) => {
      void this.alert
        .open(LawyerVerifyDialogV2Component, { channel })
        .then((isVerifiedLawyer) => {
          r(isVerifiedLawyer);
          void this.updateFromServer();
        });
    });
  }

  selectCases() {
    void this.alert.open(SelectCasesComponent, null, { disableClose: true });
  }

  selectLater(daysCount = later) {
    void this.updateUserData({
      involveUntil: String(new Date().getTime() + day * daysCount),
    }).then(() => {
      this.alert.pop(
        '우측 상단의 내 메뉴 > 내 수행사건에서 언제든지 수행여부를 표시할 수 있습니다.',
      );
    });
  }

  async updateUserData(map): Promise<void> {
    const updated = await firstValueFrom(this.api.user.updateData(map));
    if (this.user) {
      this.user.data = updated.data;
    }
  }

  afterMark() {
    this.api.userInvolveCase.getCandidateCaseList().subscribe((v) => {
      this.candidateCases = v;

      if (v.length) {
        void this.alert
          .confirm(
            '사건 수행여부 표시완료',
            '사건 수행여부 표시하기',
            `수행여부가 표시되었습니다.
아직 확인되지 않은 사건이 <b>${v?.length}건</b> 있습니다. 지금 바로 
표시하시겠습니까?<div class='mt-32 font-14 color-gray'>잘못 표시한 경우 <span onclick="zendesk()" class="color-primary cursor-pointer">고객센터</span>를 통해 수행여부를 수정할 수 있습니다.</div>`,
            '나중에',
          )
          .then((v) => {
            if (!v) {
              this.userpilotService.instance?.track(
                '수행여부 표시완료 팝업 나중에 버튼 클릭',
              );
              this.selectLater();
              return;
            }
            window.location.assign(
              `/${NEW_LBOX_PREFIX}${V2_PAGE_PATH.user.myCase}?tab=candidate`,
            );
          });
      } else {
        (window as any).openmycaseupload = () => {
          this.zone.run(() => {
            this.myCaseRegister();
          });
        };

        void this.alert
          .alert(
            '사건 수행여부 표시완료',
            null,
            '수행여부가 표시되었습니다.<br>' +
              '<b>내 메뉴 > 수행한 사건</b>에서 언제든지 확인할 수 있습니다. 잘못 표시한 경우 <span onclick="zendesk()" class="color-primary cursor-pointer">고객센터</span>를 통해 \n' +
              '수행여부를 수정할 수 있습니다.' +
              '<div class=\'color-primary mt-48\' onclick="openmycaseupload()">수행한 사건 중 엘박스에서 검색되지 않는 사건이 있나요?</div>',
          )
          .then(() => {
            this.userpilotService.instance?.track(
              '수행여부 표시완료 팝업 확인 버튼 클릭',
            );
          });
      }
    });
  }

  private checkCookie() {
    if (!checkCookie()) {
      const url = isEdge()
        ? 'https://support.microsoft.com/ko-kr/microsoft-edge/microsoft-edge%EC%97%90%EC%84%9C-%EC%BF%A0%ED%82%A4-%EB%B0%8F-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%8D%B0%EC%9D%B4%ED%84%B0-%EC%9E%84%EC%8B%9C-%ED%97%88%EC%9A%A9-597f04f2-c0ce-f08c-7c2b-541086362bd2'
        : isSafari()
        ? 'https://support.apple.com/ko-kr/guide/safari/sfri11471/mac'
        : isChrome()
        ? 'https://support.google.com/accounts/answer/61416?hl=ko'
        : '';

      void this.alert
        .confirm(
          '쿠키 허용 설정 안내',
          url ? '쿠키 허용 방법 보기' : false,
          '현재 브라우저가 쿠키를 차단하도록 설정되어 있습니다. 엘박스를 정상적으로 이용하기 위해서는 쿠키를 허용해주셔야 합니다.',
          '나중에',
          { disableClose: true },
        )
        .then((v) => {
          if (!v) {
            return;
          }
          window.open(url, '_blank');
        });
    }

    function checkCookie() {
      let cookieEnabled = navigator.cookieEnabled;
      if (!cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = document.cookie.indexOf('testcookie') != -1;
      }
      return cookieEnabled;
    }
  }

  public async checkCandidate(): Promise<boolean> {
    const DAYS_COUNT_FOR_NEXT_CHECK = 30;

    this.candidateCases = await firstValueFrom(
      this.api.userInvolveCase.getCandidateCaseList(),
    );

    if (!this.candidateCases.length) {
      return false;
    }

    if (!this.datePassed('involveUntil')) {
      return false;
    }

    void this.alert
      .suggest(
        `<b>‘${this.user.name} 변호사’</b>님으로 검색되는 판례 중 <b>수행여부가 확인되지 않은 판례가 ${this.candidateCases.length}</b>건 있습니다.<br> 본인이 수행한 사건인지 확인하시겠습니까?`,
        '수행여부 확인하기',
        '나중에',
        null,
        null,
        null,
        DAYS_COUNT_FOR_NEXT_CHECK,
      )
      .then((v) => {
        if (!v) {
          this.selectLater(DAYS_COUNT_FOR_NEXT_CHECK);
          return;
        }
        this.selectCases();
      });
    return true;
  }

  datePassed(key: string) {
    if (!this.user?.data) return true;
    if (String(this.user.data[key]) == 'true') return;
    return (
      !this.user.data[key] || Number(this.user.data[key]) < new Date().getTime()
    );
  }

  get isCompanyPlan() {
    return this.plan?.companyPlan;
  }

  ensureUserLoaded(): Promise<User> {
    return new Promise<User>((r) => {
      if (this.userLoaded) {
        r(this.user);
      } else {
        void firstValueFrom(this.userUpdated).then(() => {
          r(this.user);
        });
      }
    });
  }

  updateLqaOpen() {
    this.api.lboxUser.lqaOpen().subscribe((v) => {
      this.lboxUser.lqaOpenCases = v.lqaOpenCases;
    });
  }

  myCaseRegister() {
    window.dispatchEvent(new CustomEvent('my-case-upload-modal-open'));
    this.userpilotService.instance.trigger('q8R_Dq8uG9');
  }

  openMembershipNudgingModal() {
    window.dispatchEvent(
      new CustomEvent(CUSTOM_EVENT_NAME.openMembershipNudgingModal),
    );
  }

  async setLqaDistrict() {
    await this.alert.open(LqaDistrictSettingComponent, null, {
      disableClose: true,
      width: '596px',
    });
    this.setDistrictViewValues();
  }

  private setDistrictViewValues() {
    if (!this.lboxUser.lboxUserData?.lqaDistricts) return;
    this.lqaDistrictsValues = JSON.parse(
      JSON.stringify(this.lboxUser.lboxUserData?.lqaDistricts),
    );
    Object.keys(lqaDistricts).forEach((key) => {
      if (lqaDistricts[key].length == 1) return;
      if (
        lqaDistricts[key].find((d) => {
          return !this.lqaDistrictsValues.includes(d);
        })
      )
        return;

      this.lqaDistrictsValues.push(key + ' 전체');
      this.lqaDistrictsValues.removeAll(lqaDistricts[key]);
    });
  }

  updateLqaToggle() {
    this.api.lboxUser.toggleLqaEnable().subscribe((v) => {
      this.lboxUser = v;
    });
  }

  private checkLqaClientTarget() {
    if (this.isLawyer) return;
    const jobJson = JSON.stringify(this.lboxUser?.job);
    if (
      [
        '법조인(판사, 검사, 법무관 등)',
        '법률전문직',
        '법학전문대학원생',
      ].includes(this.lboxUser?.job?.[0]) ||
      [
        '경찰',
        '로펌',
        '법학',
        '법률',
        '손해사정',
        '법원',
        '법무',
        '변호사',
        '고용노동부',
        '보험설계사',
        '사무장',
        '채권',
      ].find((it) => jobJson?.match(it))
    )
      return;
    if (
      !this.datePassed('lqaClientTargetUntil') ||
      this.localStorage.getItem('lqaClientTargetUntil')
    )
      return;

    return true;
  }

  async moveLqaClientRegister() {
    await this.loginCheck();

    if (this.lboxUser?.lboxUserData?.lqaClientTargetUntil === true) {
      void this.router.navigateByUrl('/lqa/question/new');
      return;
    }

    void this.updateUserData({
      lqaClientTargetUntil: true,
    }).then(() => {
      this.alert.close();
      void this.router.navigateByUrl('/lqa/question/new');
    });
  }

  authorizeLawyerStudent(
    data: {
      onRegister?;
      extend?;
      fileList?;
      uploadOnly?;
      channel: string; // 로스쿨 학생 인증 시작한 경로
    } = {} as any,
    openWhenBottomSheet = false,
  ) {
    data.fileList = [];
    return this.alert.open(
      LawSchoolAuthorizeComponent,
      data,
      { width: '500px' },
      { id: 'law-school-authorize' },
      openWhenBottomSheet,
    );
  }

  verifyLawyerByUpload(uploadDoneMessage, from) {
    const data = { uploadDoneMessage, from } as any;
    data.fileList = [];
    return this.alert.open(
      LawyerVerifyByUploadComponent,
      data,
      { width: '640px' },
      null,
    );
  }

  getItem(key: string): any {
    if (this.user?.data?.storage) {
      return this.user?.data?.storage[key];
    }
    try {
      return this.localStorage.getItem(key);
    } catch (e) {}
  }

  setItem(key: string, value: string) {
    if (this.user) {
      this.ensureStorage();
      this.user.data.storage[key] = value;
      this.api.user.updateData({ storage: this.user.data.storage }).subscribe();
      return;
    }
    try {
      this.localStorage.setItem(key, value);
    } catch (e) {}
  }

  private ensureStorage() {
    if (!this.user.data) {
      this.user.data = {};
    }
    if (!this.user.data.storage) {
      this.user.data.storage = {};
    }
  }

  private async sendUserData(u: User) {
    const v = isProduction()
      ? await firstValueFrom(this.api.user.getCurrentUserProperties())
      : null;
    if (isPlatformServer(this.platform)) return;
    if (this.ga.tracking.userProperties) return;
    const userInfo = this.getUserInfo(v, u);
    this.ga.tracking.userProperties = userInfo;
    // 유저파일럿
    if (
      localStorage.getItem('userTokenUpdated') &&
      Number(localStorage.getItem('userTokenUpdated')) >
        new Date().getTime() - day
    )
      return;

    const event = new Identify();
    Object.keys(userInfo).forEach((it) => {
      if (!userInfo[it]) return;
      event.set(it, userInfo[it]);
    });

    log('userInfo', userInfo);

    identify(event, { user_id: String(u?.id) });

    this.userpilotService.instance?.identify(u?.id, {
      ...userInfo,
      name: userInfo.userName,
      email: userInfo.userEmail,
      created_at: userInfo.userSignedup,
    });

    localStorage.setItem('userTokenUpdated', new Date().getTime().toString());
  }

  private getUserInfo(v: UserProperties, u: User) {
    const membership = this.isPublicDomain
      ? 'NONPROFIT'
      : !this.plan
      ? 'NONE'
      : this.isCompanyPlan
      ? 'COMPANY'
      : this.plan.plan == 'LAW_SCHOOL'
      ? 'LAWSCHOOL'
      : this.plan.payment
      ? 'NORMAL'
      : 'FREE';

    return {
      user_id: u?.id,
      userId: u?.id,
      userMembership: membership,
      userMembershipName: this.plan?.planName,
      userName: u?.name,
      userEmail: u?.email,
      userJob: convertJobDetailsToLogProperty(this.lboxUser?.jobDetails).job,
      userSignedup: moment(u?.createdAt).format('YYYY-MM-DDTHH:mm:ss'),
      userAssociation: this.lboxUser?.association ?? '', // 소속변호사회
      ...this.getExamInfo(this.lboxUser?.job),
      ...this.getInstituteAndYearInfo(this.lboxUser?.job),
      ...v, // 여기에는 userNthMembership, userTotalPayment 두가지 정보만 전달
    };
  }

  getExamInfo(job: any) {
    log(job);

    const LAYWER_JOBS = new Set<string>([
      '변호사',
      '법조인(판사, 검사, 법무관 등)',
    ]);

    let exam = undefined;
    let examNumber = undefined;

    if (LAYWER_JOBS.has(job[0])) {
      exam = job[2];
      examNumber = job[3];
    }

    return {
      userExam: exam,
      userExamNumber: examNumber,
    };
  }

  getInstituteAndYearInfo(job: any) {
    const ADMISSION_JOBS = new Set<string>([
      '공무원(법원, 검찰, 경찰 등)',
      '법학전문대학원생',
      '대학생/대학원생',
    ]);

    let admissionYear = undefined;
    let graduationYear = undefined;
    let institute = undefined;

    if (ADMISSION_JOBS.has(job[0])) {
      institute = job[1];
      admissionYear = job[2];
      if (job.length > 3) {
        graduationYear = job[3];
      }
    }

    return {
      userAdmissionYear: admissionYear,
      userGraduationYear: graduationYear,
      userInstitute: institute,
    };
  }

  getShowsSourceWhenCopyTextOption() {
    return this.api.user.getShowsSourceWhenCopyTextOption();
  }

  saveShowsSourceWhenCopyText(state: 'ON' | 'OFF') {
    this.api.user.updateShowsSourceWhenCopyText(state).subscribe();
  }

  /**
   * 비영리단체 채널로 접속했는가
   * - URL에 non-profit 텍스트 포함 -> 비영리단체 URL로 접속한 경우
   * - URL이 lbox.kr로 끝나지 않음 -> 프록시 서버로 접속하는 경우
   */
  get isPublicDomain() {
    const hostname = window?.location?.hostname;
    if (hostname === undefined || hostname === 'localhost') {
      return false;
    }

    // FIXME: non-profit.lbox.kr 도메인 없어지면 `non-profit` 관련 조건 삭제하기
    const isPublicPlanDomain =
      hostname.includes('non-profit') || hostname.includes('public');
    const isUsingProxyServer = !hostname.endsWith('lbox.kr');

    return isPublicPlanDomain || isUsingProxyServer || this.isPoliceDomain;
  }

  /**
   * 경찰청 복지몰과 연결된 도메인인지 판단한다
   */
  get isPoliceDomain() {
    const hostname = window?.location?.hostname;
    if (hostname === undefined || hostname === 'localhost') {
      return false;
    }

    return hostname === 'police-non-profit.lbox.kr';
  }
}

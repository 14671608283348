import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filePath',
})
export class FilePathPipe implements PipeTransform {
  transform(value: any, privateType?): any {
    if (privateType == 'lqaImage')
      return '/api/matchedLawyer/file?path=' + encodeURI(value);
    if (!privateType)
      return `https://lbox-images.s3.ap-northeast-2.amazonaws.com/` + value;
    return '/api/upload?path=' + encodeURI(value);
  }
}
